import React from "react";
import loadable from '@loadable/component';
import { StaticQuery, graphql } from "gatsby";

const Vinitos = loadable(() => import('../components/Vinitos'));

export default function vinitos() {
  return (
    <StaticQuery
      query={graphql`
        query getWines {
          allWine(sort: {fields: index, order: ASC}) {
            edges {
              node {
                colors {
                  button
                  title
                }
                data
                description
                drink
                images
                link
                name
                notes
                review
                temperature
                thumbnail
                title
                background
                index
              }
            }
          }
        }`}
      render={({allWine}) => <Vinitos rawData={allWine.edges} />}
    />
  );
}